import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection , RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions in Patients With Advanced MSI-H/dMMR Colorectal Cancer (CRC) - HCP`,
    keywords: `msi-h colorectal cancer adverse reactions`,
    description: `Learn about selected immune-mediated adverse reactions and the most common adverse reactions to KEYTRUDA® (pembrolizumab) in patients with advanced MSI-H/dMMR colorectal cancer (CRC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/msi-h-dmmr-colorectal-cancer/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with unresectable or metastatic MSI⁠-⁠H or dMMR colorectal cancer (CRC) as determined by an FDA-approved test."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/msi-h-dmmr-colorectal-cancer/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 23;
const jobCode = jobCodes[23].jobCode;


const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: 'MSI&#8288;-&#8288;H/dMMR Cancers', url: '/safety/adverse-reactions/msi-h-dmmr/' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Common Adverse Reactions with KEYTRUDA'}
]

const Page = ({ location }) => {

    return (

        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>

            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                The most common adverse reactions for
                                KEYTRUDA (reported in ≥20% of patients) were
                                fatigue, musculoskeletal pain, rash, diarrhea,
                                pyrexia, cough, decreased appetite, pruritus,
                                dyspnea, constipation, pain, abdominal pain,
                                nausea, and hypothyroidism.
                            </TextBlock>
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
